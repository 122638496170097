import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/JumbotronPanel.css";
import AppNavbar from "./AppNavbar";
import QuickSearchPanel from "./QuickSearchPanel";

function JumbotronPanel() {
  return (
    <React.Fragment>
      <section>
        <Container fluid={true} className="jumbotron-image">
          <AppNavbar type={1} customStyleClassName={"navbar-shadow-none"} />
          <Row className="flex-column justify-content-center" style={{ height: "80vh" }}>
            <Col xl={12} lg={12} xs={12} md={12} className="text-center">
              <span className="home-page-main-sub-heading text-black">
                {window?.__RUNTIME_CONFIG__?.app_title
                  ? window.__RUNTIME_CONFIG__.app_title
                  : "Harry Gentle Resource Centre"}
              </span>
              <h2 className="text-black home-page-main-heading">
                <b>
                  {window?.__RUNTIME_CONFIG__?.app_sub_title
                    ? window.__RUNTIME_CONFIG__.app_sub_title
                    : "Dictionary of Biography"}
                </b>
              </h2>
            </Col>
          </Row>

          {/* <QuickSearchPanel /> */}
          <div style={{color:"#D3D3D3", textAlign: "center"}}>
            Australian School, 19th century View from Main Range near Spicers Peak, Queensland, Australia c.1880s Watercolour on paper, 28 x 48.2cm, Purchased 2005 Collection: Queensland Art Gallery | Gallery of Modern Art
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default JumbotronPanel;
