import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../css/SearchButtonPanel.css";
import { LinkContainer } from "react-router-bootstrap"; 

function SearchButtonPanel() {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [surname, setSurname] = useState("");

  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(
      `/search?first_name=${encodeURIComponent(firstName)}&middle_name=${encodeURIComponent(
        middleName
      )}&surname=${encodeURIComponent(surname)}`
    );
  };

  return (
    <section className="p-2 search-button-panel text-white">
      <Container>
          <Row>
            <Col md={12}>
              <Row className="flex-row justify-content-center search-banner">
                <Col></Col>
                <Col className="text-center">
                  <LinkContainer style={{border: "solid", borderStyle: "solid", borderColor: "black"}} to="/search">
                    <Button
                      size="lg"
                      variant="primary"
                      className="shadow-lg"
                      target="_blank"
                      href="https://harrygentle.griffith.edu.au"
                    >
                    Search the Dictionary
                    </Button>
                  </LinkContainer>
                </Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
      </Container>
    </section>
  );
}

export default SearchButtonPanel;
